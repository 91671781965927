import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Login from "../components/Login/Login"

export const IndexPage = () => (
  <>
    <Login />
  </>
)

const LayoutIndexPage = () => (
  <Layout>
    <IndexPage />
  </Layout>
)

export default LayoutIndexPage
